.table-luxottica-container{

}
.table-luxottica{
}

.table-luxottica .ant-table-thead .ant-table-cell {
    background-color: var(--main-color);
    color: var(--secondary-color);
}

.table-luxottica-container .expand-all-container{
    margin-bottom: 1em;
    margin-left: 1em;
}
.table-luxottica-container .expand-all-container .icon-expand{
    /*background-color: var(--secondary-color);
    color: var(--main-color);
    border: 1px solid var(--main-color);*/
    margin-right: 1em;
}
/* Override antd css */

:where(.table-luxottica).ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child {
    border-start-start-radius: 8px;
    border-end-start-radius: 8px;
}

:where(.table-luxottica).ant-table-wrapper .ant-table-container table>thead>tr:last-child >*:last-child{
    border-start-end-radius: 8px;
    border-end-end-radius: 8px;
}

:where(.table-luxottica).ant-table-wrapper .ant-table-cell{
    padding: 8px 8px !important;
}

:where(.table-luxottica).ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
     background: var(--main-color);
}

:where(.table-luxottica).ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background: var(--main-color);
}