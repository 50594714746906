.practices-details{
    height: 100%;
}
.practices-details .row-container{
    /*height: calc(83vh - var(--page-title-height));*/
    min-height: calc(80vh - var(--page-title-height));
}
.practices-details .row-container .col-container{
    border: 1px solid var(--third-color);
    border-radius: var(--main-border-radius);
    box-shadow: 2px 2px 1px var( --third-color-opacity-60);
    height: 100%;
}
