.shop-detail {
    border: solid 2px var(--main-color);
    border-radius: 5px;
    height: 420px;
}

.shop-detail-header {
    border: solid 2px var(--main-color);
    border-bottom-left-radius: var(--main-border-radius) ;
    border-bottom-right-radius: var(--main-border-radius) ;
    background-color: var(--main-color);
    padding: 0 5px;
    color: white;
    margin-top: 0;
}

.shop-detail-body {
    padding: 10px;
    height: calc(450px - 26px);
    overflow-y: scroll;
}


.shop-detail-body p {
    margin: 0;
}