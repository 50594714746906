.login-button {
    background-color: var(--main-color);
    color: white;
    font-weight: bold;
}

.luxottica-button-success, .luxottica-button-success:active {
    background-color: var(--selected-color);
    color: white;
}

.luxottica-button-success:hover {
    color: var(--selected-color) !important;
    border-color: var(--selected-color) !important;
    box-shadow: 0 2px 0 var(--selected-color-opacity-02);
}

