.luxottica-icon {
    --icon-width: 30px;

    background-color: var(--main-color);
    color:var( --secondary-color);
    border-radius: 100%;
    padding: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    display: inline-flex;
    align-content: center;
    justify-content: center;
    width: var(--icon-width);
    height: var(--icon-width);
}

.luxottica-icon-sm {
    font-size: 18px;
}

.luxottica-icon-md {
    font-size: 30px;
}

.luxottica-icon-lg {
    font-size: 50px;
}