.button-checkbox-selected {
    color: var(--selected-color) !important;
    border-color: var(--selected-color) !important;
}
.button-checkbox-selected:hover {
    color: var(--selected-color) !important;
    border-color: var(--selected-color) !important;
    box-shadow: 0 2px 0 var(--selected-color-opacity-02);
}

.button-readonly {
    color: rgba(0, 0, 0, 0.88);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    outline: none;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background: #ffffff none;
    border: 1px solid #d9d9d9;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: none;
    font-size: 14px;
    line-height: 1.5714285714285714;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
}
