.square-tile{
    border: solid 3px #000000;
    border-radius: 10px;
    padding: 40px;
    position: relative;
    cursor: pointer;
    height: 100%;

}
.square-tile .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 2px solid rgb(0, 0, 0);
    top: -30px;
    left: 20px;
    position: absolute;
    font-size: 50px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: black ;
    color:white;
}
.square-tile:hover {
    transform: scale(1.05); 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);  
    background-color: rgba(220,220,220,0.1);
  }