.info-menu-container{

}

.info-menu-container .flex-container{
    margin-top: 25px;
}

.info-menu-container .flex-container .info-menu-square-tile{
    width: 400px;
    max-height: 400px;
    height: 190px;
}