.voucher-list {
    list-style-type: none;
    padding-left: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    /*max-height: 570px;*/
    max-height: 70vh;
}

.voucher-list li {
    padding: 10px 0;
}