body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-color: rgb(0, 0, 0);
  --main-color-opacity-60: rgba(0, 0, 0,0.6);
  --secondary-color:rgb(255, 255, 255);
  --third-color: rgb(222, 226, 230);
  --third-color-opacity-60: rgba(222, 226, 230,0.6);
  --selected-color:rgb(7,196,113);
  --selected-color-opacity-02: rgba(7, 196, 113, 0.02);
  --navbar-height:60px;
  --page-title-height:55px;
  --message-textbox-height:72px;
  --main-border-radius:5px;
  --main-border:1px solid var(--third-color);
  --main-box-shadow:2px 2px 1px var( --third-color-opacity-60);
}

.pointer {cursor: pointer;}

.width-100{
  width: 100%;
}
.width-25{
  width: 25%;
}
.width-20{
  width: 20%;
}
.width-40{
  width: 40%;
}
.float-left{
  float: left;
}
.float-right{
  float: right;
}
