.practices-details-columns{
    padding: 3px 10px;
}
.practices-details-columns .title{
    margin-top:7px;
}
.practices-details-columns .vertical-divider{
    height:60px
}

.practices-details-columns .labels{
    margin-bottom:7px;
}

.practices-details-columns .bottom-container{
    position: absolute;
    bottom: 10px;
    width: 75%;
}
.practices-details-columns .button{

    width: 100%;
}

.practices-details-columns .margin-y-10px{
    margin-top: 10px;
    margin-bottom: 10px;
}
.practices-details-columns .margin-y-5px{
    margin-top: 5px;
    margin-bottom: 5px;
}
.practices-details-columns .margin-y-1px{
    margin-top: 1px;
    margin-bottom: 1px;
}

.practices-details-columns .margin-left-5px{
    margin-left: 5px;
}

.practices-details-columns  .body-state-column {
    height: 70vh;
    overflow-y: auto;
}

@media only screen and (max-width: 1200px) {
    .practices-details-columns  .body-state-column {
        max-height: 63vh;
        overflow-y: auto;
    }
}

.practices-details-columns .note-container{
    width:100%;
    overflow: auto;
    height: calc(100vh - var(--navbar-height) - var(--page-title-height) - 100px - var(--message-textbox-height));
}
.practices-details-columns .chat-container{
    width:100%;
    overflow: auto;
    height: calc(100vh - var(--navbar-height) - var(--page-title-height) - 125px - var(--message-textbox-height));
}

.practices-details-columns .chat-container .messages-container{
    width:100%;
    overflow: auto;
    /*
    TODO CHECK problemi con overflow ?
    height:calc(100vh - var(--navbar-height) - var(--page-title-height) - var(--message-textbox-height) - 100px);
    */
    height:100%;
}
.practices-details-columns .messages-container .chat-message{
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    box-shadow: var(--main-box-shadow);
    width: 70%;
    padding: 10px;
    position: relative;
    background-color: var(--third-color-opacity-60) ;
}

.practices-details-columns .messages-container .chat-message .state-label-container{
    position: absolute;
    top: -10px;
    right: 20px;
}
