
.home-container .row-container {
    padding: 20px;
    padding-top: 40px;
}

.home-container .row-container .tile-container{
    max-width: 400px;
    max-height: 400px;
    height: 190px;
}