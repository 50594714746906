.practice-discounts-modal{
    top:0;
    left: 20%;
}
.practice-discounts-modal .body-modal{
    margin-top: 20px;
    height: 85vh;
    overflow-y: auto;
    ;

}
.practice-discounts-modal .body-modal .discounts-elements-container{
    border: 1px solid var(--third-color);
    border-radius: 5px;
    padding: 10px;
}
.practice-discounts-modal .body-modal .ant-divider-horizontal{
    margin: 5px 0 !important;
}

/*
.practice-discounts-container .ant-modal-content{
    height: 100px;
}*/
