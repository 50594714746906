.agreement-detail {
    border: solid 2px var(--main-color);
    border-radius: var(--main-border-radius);
    /*min-height: 55vh;*/
    height: 100%;
    /*
    overflow-y: auto;
    */
}

.agreement-detail .agreement-detail-header {
    border: solid 2px var(--main-color);
    border-bottom-left-radius: var(--main-border-radius);
    border-bottom-right-radius: var(--main-border-radius);
    background-color: var(--main-color);
    color: white;
    margin-top: 0;
    padding: 0 5px;
}

.agreement-detail .agreement-detail-body {
    padding: 10px;
    width: 250px;
    height: 100%;
    overflow-y: auto;
}