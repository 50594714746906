.dashboad-page{

}
.dashboad-page .ant-card-body{
    padding: 7px 12px;
}
.dashboad-page .dashboard-field {
    width:200px
}
.dashboad-page .dashboard-card{
    margin-top: 10px;
    min-height: 65vh;
    height: 100%;
    overflow: auto;
}

.dashboad-page .button-container{
    margin-top: 10px;

}

.dashboad-page .button-container .button{
    float:right;
}

.dashboad-page .button-container .button-submit{
    margin-left: 10px;
}
.dashboad-page .buttons-submit{
    margin-top: 5px;
    width:99%;
}