.message-textbox-container{
    height:var(--message-textbox-height);
}

.state-label {
    cursor: pointer !important;
}

.state-label-disabled {
    cursor: not-allowed !important;
}