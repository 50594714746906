
.base-layout .nav-bar {
    height: var(--navbar-height);
    max-height: var(--navbar-height);
    background-color: var(--third-color);
}

.base-layout .nav-bar.nav-bar-dev {
    background-color: rgba(255, 0, 0, 0.4);
}

.base-layout .nav-bar .user-menu {
    .user-image{

        max-height: var(--navbar-height);
        width:var(--navbar-height) ;
        max-width: var(--navbar-height);
    }
}

/*
!* override antd css *!
*/
.base-layout .nav-bar .ant-menu-horizontal {
    border-bottom: 0;
}
.base-layout .nav-bar .ant-menu-item-selected {
    color: black;
}
.base-layout .nav-bar .ant-menu-item:hover::after{
    border-bottom-width: 0;

}
.base-layout .nav-bar .ant-menu-item:hover::after{
    border-bottom-width: 0;
}
.base-layout .nav-bar .ant-menu-item-selected::after{
    border-bottom-width: 0
}

.base-layout .nav-bar .logo-container{
    max-height: var(--navbar-height);
    max-width: 100%;

}
.base-layout .nav-bar .logo-container .logo{
    max-width: 200px;
    max-height: var(--navbar-height);
}
.base-layout .main-container{
    height: calc(100vh - var(--navbar-height));

}
@media only screen and (max-width: 1200px) {
    /* bruttura per schermi piccoli*/
    .base-layout .main-container{
        height: calc(96vh - var(--navbar-height));
    }

}
.base-layout .main-container .content{
    padding: 15px;
    overflow-y: auto;
}

.base-layout .main-container .footer {
/*    display: flex;
    !*
    justify-content: flex-end;
    *!
    position:absolute;
   !* bottom: 42px;
    right: 50px;*!
    justify-content: center;
    bottom: 5vh;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;*/
}

.base-layout .main-container .footer .text {
    margin-right: 5px ;
    cursor: default;
}
.base-layout .main-container .content .flexbox-container {
    height: 100%;
}
.base-layout .main-container .content .page-body{
    z-index: 1;
}