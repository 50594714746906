.todolist-filters .tile {
    background-color: var(--main-color);
    color: var(--secondary-color);
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    padding: 5px;
    box-shadow: var(--main-box-shadow);
    cursor: pointer;
}

.todolist-filters .tile .text {
    color: var(--secondary-color);
    max-width: 140px;
}
.todolist-filters .tile .number{
    color: var(--secondary-color);
    font-size: 30px;
    max-width: 80px;
}

.todolist-filters .tile-selected {
    background-color: var(--third-color);
    color: var(--main-color);
    border:1px solid var(--main-color);
}
.todolist-filters .tile-selected .text {
    color: var(--main-color);
}
.todolist-filters .tile-selected .number{
    color: var(--main-color);
}

.tile-disabled {
    cursor: not-allowed !important;
}

.refundTypeIndicator {
    background-color: var(--main-color);
    color: var(--secondary-color);
    border-radius: var(--main-border-radius);
    border: var(--main-border);
    padding: 15px;
    box-shadow: var(--main-box-shadow);
    flex: 1;
}

.refundTypeIndicator.clickable {
    cursor: pointer;
}

.refundTypeIndicator.active {
    background-color: var(--selected-color) !important;
}