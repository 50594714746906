.icon-button{
}
.icon-button-selected{
    transform: scale(1.20);
}
.icon-button-selected .luxottica-icon{
    background-color: var( --secondary-color);
    color: var(--main-color);

}

