.discount-content {
    border: solid 2px lightgray;
    border-radius: 5px;
    padding: 10px;
    height: 600px;
}

.voucher-detail-list {
    overflow: auto;
    white-space: nowrap;
    list-style-type: none;
    padding-left: 0;
    height: inherit;
}

.voucher-detail-list .voucher-detail-list-element {
    display: inline-block;
    margin-right: 10px;
    height: 100%;
}